import './App.css';
import renderHTML from 'react-render-html';
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";

import BGIcon from './lbg.png'
import ENIcon from './len.png'
import I1 from './i1.jpg'
import I2 from './i2.jpg'
import I3 from './i3.jpg'
import I4 from './i4.jpg'
import I5 from './i5.jpg'
import I6 from './i6.jpg'
import TexPrint from './texprint.jpg'
import KNK from './knk.jpg'


// let backend = "http://localhost:3131"
let backend = "https://www.hitex-r.eu/data/" 
//let backend = "http://172.104.228.97/data/"

function App() {
  const [ccomp, setComp] = React.useState(0)
  const [productSource, setProductSource] = React.useState([])
  const [eventSource, setEventSource] = React.useState([])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [ccomp])

  const [lang, setLang] = React.useState("En")
  const onSetLang = (nl) => { setLang(nl.target.alt) }

  const [width, setWidth] = useState(window.innerWidth)
  function handleWindowSizeChange() { setWidth(window.innerWidth) }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => { window.removeEventListener('resize', handleWindowSizeChange) }
  }, [])
  let isMobile = (width <= 1050)

  useEffect(() => {
    if (!productSource.length) {
      fetch(backend + "/prod", {
        headers: { "Content-Type": 'application/json' },
      }).then(
        res => res.json()
      ).then(
        re => {
          setProductSource(re)
        }
      ).catch(
        err => { console.log("fetching product error", err) }
      )
    }
    if (!eventSource.length) {
      fetch(backend + "/ev", {
        headers: { "Content-Type": 'application/json' },
      }).then(
        res => res.json()
      ).then(
        re => {
          setEventSource(re)
        }
      ).catch(
        err => { console.log("fetching product error", err) }
      )
    }
  })

  return (
    <Router>
      <Switch>
        <Route path='/manage'>
          <ManageForm eventSource={eventSource} productSource={productSource} setEvents={setEventSource} setProducts={setProductSource} />
        </Route>
        <Route path='/'>
          {
            isMobile
              ? <ABody lang={lang} setLang={onSetLang} isMobile={isMobile} ccomp={ccomp} setComp={setComp} productSource={productSource} eventSource={eventSource} />
              : <div className="App">
                <div className="Abg"></div>
                <div className="boxl" >
                  <div className="Left" />
                </div>
                <div className="cbox">
                  <ABody lang={lang} setLang={onSetLang} isMobile={isMobile} ccomp={ccomp} setComp={setComp} productSource={productSource} eventSource={eventSource} />
                </div>
                <div className="boxr">
                  <div className="Right" style={{ justifyContent: 'flex-start' }} />
                </div>
              </div>
          }
        </Route>
      </Switch>
    </Router>
  )
}

function formatDate() {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('.');
}


const ManageForm = ({ eventSource, productSource, setEvents, setProducts }) => {
  const [type, setType] = React.useState(0) // 0 products 1 events 
  const [mode, setMode] = React.useState(0) // 0 add 1 rem 
  const [stats, setStats] = React.useState('')
  const [key, setKey] = React.useState("")
  let history = useHistory()

  const addEvent = (ten, tbg, ben, bbg, pic) => {
    console.log("adding event", key, ten, tbg, ben, bbg)
    let dat = new FormData()
    dat.append('a', key)
    dat.append('ten', ten)
    dat.append('tbg', tbg)
    dat.append('ben', ben)
    dat.append('bbg', bbg)
    dat.append('pic', pic) // pic last
    fetch(backend + "/ev", {
      method: 'POST',
      body: dat
    }).then(
      res => res.json()
    ).then(
      re => {
        if (re === undefined) logOut()
        else if (re.a !== -1) {
          // prepend local data
          var ni = [[formatDate(), "/e" + re.a + ".jpg"], [ten, tbg], [ben, bbg]]
          setEvents(eventSource.unshift(ni))
          setStats("Добавянето е успешно")
        } else logOut()
      }
    ).catch(
      err => { console.log("errro is", err); logOut() }
    )
  }
  const addProduct = (ten, tbg, pic, gen, cat) => {
    let dat = new FormData()
    dat.append('a', key)
    dat.append('ten', ten)
    dat.append('tbg', tbg)
    dat.append('gen', gen)
    dat.append('cat', cat)
    dat.append('pic', pic) // pic last
    fetch(backend + "/prod", {
      method: 'POST',
      body: dat
    }).then(
      res => res.json()
    ).then(
      re => {
        if (re === undefined) logOut()
        else if (re.a !== -1) {
          // prepend local data
          var ni = [re.a, ten, tbg, gen, cat]
          setProducts(productSource.unshift(ni))
        } else logOut()
      }
    ).catch(
      err => { console.log("errro is", err); logOut() }
    )
  }
  const login = (u, p) => {
    let dat = { a: u, b: p }
    fetch(backend + "/login", {
      method: 'POST',
      headers: { "Content-Type": 'application/json' },
      body: JSON.stringify(dat)
    }).then(
      res => res.json()
    ).then(
      re => {
        if (re.a === '' && re.b !== '') {
          setKey(re.b)
          setStats("Успешен вход")
        } else {
          setStats("Неуспешен вход")
        }
      }
    ).catch(
      err => { setStats("Неуспешен вход") }
    )
  }
  function logOut() {
    setKey('')
    setStats('Невалидна сесия')
  }

  return (
    <div style={{ margin: "20px" }}>
      <form onSubmit={(e) => { e.preventDefault(); login(e.target.username.value, e.target.password.value); return false }}>
        {(key === "") && <div>
          <label >Потребител:</label> <br />
          <input type="text" id="username" name="username" required /> <br /> <br />
          <label >Парола:</label> <br />
          <input type="password" id="pass" name="password" minLength="8" autoComplete="on" required />  <br />
          <input type="submit" value="Вход" /> <br /> <br />
        </div>}
      </form>
      {stats} <br /> <br />
      <input type="button" value="Обратно към Сайта" onClick={() => { history.push("/") }} /> <br /> <br />
      { (key !== "") && <div>
        <input type="button" value="Редактирай Продукти" disabled={type === 0} onClick={() => { setType(0) }} /> { }
        <input type="button" value="Редактирай Събития" disabled={type === 1} onClick={() => { setType(1) }} /> <br /> <br />
        <input type="button" value="Добавяне" disabled={mode === 0} onClick={() => { setMode(0) }} /> { }
        <input type="button" value="Премахване" disabled={mode === 1} onClick={() => { setMode(1) }} /> <br /> <br />
      </div>}
      {(mode === 0) && key && <AddItem Type={type} Action={type ? addEvent : addProduct} />}
      {(mode === 1) && key && <RemItem Type={type} Data={ type ? eventSource : productSource} SetP={setProducts} SetE={setEvents} SetSt={setStats} />}
    </div>
  )
}

const RemItem = ({ Type, Data, SetP, SetE, SetSt }) => {
  // show entries with appropriate delegate, request delete from the server
  // do not show cat for events!!!
  const remProduct = (ind, pid) => {
    fetch(backend + "/prod", {
      method: 'DELETE',
      headers: { "Content-Type": 'application/json' },
      body: JSON.stringify([ind, pid])
    }).then(
      res => res.json()
    ).then(
      re => {
        if (re.a === pid) {
          SetSt("Продукт " + Data[ind][2] + " премахнат успешно")
          var ndat = Data.slice(0)
          ndat.splice(ind, 1)
          SetP(ndat)
        }
      }
    ).catch(
      err => { console.log(err) }
    )
  }

  const remEvent = (ind) => {
    fetch(backend + "/ev", {
      method: 'DELETE',
      headers: { "Content-Type": 'application/json' },
      body: JSON.stringify([ind])
    }).then(
      res => res.json()
    ).then(
      re => {
        if (re.a === ind) {
          SetSt("Събитие " + Data[ind][1][1] + " премахнато успешно")
          var ndat = Data.slice(0)
          ndat.splice(ind, 1)
          SetE(ndat)
        }
      }
    ).catch(
      err => { console.log(err) }
    )
  }

  return (
    <div>
      {
        Data.map((item, index) => {
          return (
            <div key={index} >
              <input type="button" value="Премахване" onClick={() => { Type ? remEvent(index) : remProduct(index, item[0]) }} />
              {" "}
              {Type ? item[1][1] : item[2]}
            </div>)
        })
      }
    </div>
  )
}

const AddItem = ({ Type, Action }) => {
  const [img, setImg] = React.useState(null)
  let handle = (e) => {
    let titleEn = e.titleEn.value
    let titleBg = e.titleBg.value
    let descEn
    let descBg
    let gen = 1
    let cat = 1
    if (Type) {
      descEn = e.descEn.value
      descBg = e.descBg.value
    } else {
      let gtext = e.gent.value
      if (gtext === "men") gen = 1
      else if (gtext === "women") gen = 2
      else if (gtext === "kids") gen = 4
      let ctext = e.catt.value
      if (ctext === "pyj") cat = 1
      else if (ctext === "und") cat = 2
      else if (ctext === "daily") cat = 4
      else if (ctext === "tshirt") cat = 8
      else if (ctext === "sport") cat = 16
    }
    let image = e.image.files[0]
    if (Type) {
      Action(titleEn, titleBg, descEn, descBg, image) // then
    } else {
      Action(titleEn, titleBg, image, gen, cat) // then
    }
  }
  return (
    <form id={"addForm"} onSubmit={(e) => { e.preventDefault(); handle(e.target); e.target.reset(); setImg(null); return false }}>
      <label>Наименование Български:</label> <br />
      <textarea id="titleBg" name="titleBg" cols="60" rows="4" required /> <br /> <br />
      <label>Наименование Английски:</label> <br />
      <textarea id="titleEn" name="titleEn" cols="60" rows="4" required /> <br /> <br />
      { Type === 1 && <div>
        <label>Описание Български:</label> <br />
        <textarea id="descBg" name="descBg" cols="60" rows="10" required /> <br /> <br />
        <label>Описание Английски:</label> <br />
        <textarea id="descEn" name="descEn" cols="60" rows="10" required /> <br /> <br />
      </div>}
      { (Type === 0) && <div>
        <label>Категория:</label><br />
        <select id="gent">
          <option value="men">Мъжки</option>
          <option value="women">Дамски</option>
          <option value="kids">Детски</option>
        </select>
        <select id="catt">
          <option value="pyj">Пижами</option>
          <option value="und">Бельо</option>
          <option value="daily">Ежедневни</option>
          <option value="tshirt">Тениски</option>
          <option value="sport">Спортни</option>
        </select> <br /> <br />
      </div>}
      <label>Изображение:</label> <br />
      <input type="file" id="image" name="image" accept="image/jpeg" text="aaa" required onChange={(e) => { setImg(URL.createObjectURL(e.target.files[0])) }} /> <br /><br />
      <img src={img} style={{ width: "50%" }} /><br />
      <input type="submit" value="Добави" />
    </form>
  )
}


const ABody = ({ isMobile, lang, setLang, ccomp, setComp, eventSource, productSource }) => {
  const [ol, setOl] = React.useState("")
  function resetOl() { setOl("") }
  const [decl, setDecl] = React.useState(false)
  let comp
  let lng = (lang === "En") ? 0 : 1

  switch (ccomp) {
    case 0: comp = <Home Lang={lng} setOl={setOl} setComp={setComp} prodSrc={productSource.slice(0, 8)} evSrc={eventSource} />; break
    case 1: comp = <About Lang={lng} />; break
    case 2: comp = <Products Lang={lng} setOl={setOl} dsrc={productSource} />; break
    case 3: comp = <Partners Lang={lng} />; break
    case 4: comp = <Events Lang={lng} EventData={eventSource} />; break
    case 5: comp = <Contacts Lang={lng} />; break
  }
  return (
    <div className="AppBody">
      <div className="Header">
        <div className="Lang">
          {lang === "En" ? (<a onClick={setLang}><img alt="Bg" src={BGIcon} /></a>) : (<a onClick={setLang}><img alt="En" src={ENIcon} /></a>)}
        </div>
      </div>
      <Menu MenuItems={MenuItems} setComp={setComp} Lang={lng} />
      <div className="Page">
        {comp}
      </div>
      <div className="Footer">
        <div style={{ display: "flex", flexDirection: "row", width: "100%", color: "yellow" }}>
          <div style={{ flex: "1", marginLeft: "20px", alignItems: "center" }}>
            <img src={"/gdpr.png"} />
          </div>
          <div style={{ flexDirection: "column", fontSize: "14px" }}>
            <div style={{ flex: "100", display: "flex", justifyContent: "space-between", paddingLeft: "50px", paddingRight: "50px", color: "white", fontSize: "17px" }}>
              {MenuItems.map((item, index) => {
                return <div key={index} onClick={() => { setComp(index) }} style={{ cursor: "pointer", textDecoration: "underline" }}>{item[lng]}</div>
              })}
            </div>
            <p style={{ width: "100%", wordBreak: "break-all", whiteSpace: "break-spaces", wordWrap: "break-word", lineHeight: "22px" }}>
              <span style={{ cursor: "pointer", textDecorationLine: 'underline', fontSize: "18px" }} onClick={() => { setDecl(true) }}> {DeclTitle[lng]} </span>
              <br />
              {FooterText[lng]}
            </p>
          </div>
          <div style={{ flex: "1", marginRight: "20px" }}>
            <img src={"/cofund.png"} />
          </div>
        </div>
      </div>
      { (ol !== "") && <div className="Overlay" onClick={resetOl}><img src={ol} className="OLImage"></img></div>}
      { decl && <div className="Overlay" onClick={() => { setDecl(false) }}>
        <div className="OLForm">
          <img src={"/gdpric.png"} style={{margin: "40px", alignItems: "center"}} />
          <div className="SecTitle" style={{margin: "40px"}}> {DeclTitle[lng]} </div>
          <div className="SecText" style={{margin: "40px", lineHeight: "60px", fontSize: "26px"}}> {DeclText[lng]} </div>
        </div>
      </div>}
    </div >
  )
}

const DeclTitle = [
  "Privacy protection declaration",
  "Декларация за поверителност на личните данни"
]

const DeclText = [
  "HiTex-R doesn't collect or store any private information!\nThis site doesn't use tracking cookies!\nEnjoy your visit!",
  "ХайТекс-Р не събира или съхранява лични данни!\nТози сайт не използва проследяващи бисквитки!\nПриятно сърфиране!"
]

const FooterText = [
  "Co-funded through the EU \"Innovation and Competition\" operational program.\nHiTex-R Ltd. 2021",
  "Съфинансиран по оперативна програма \"Иновации и Конкурентноспособност\" на ЕС.\nХайТекс-Р ЕООД 2021"
]

const MenuItems = [
  ["Home", "Начало"],
  ["About", "За нас"],
  ["Products", "Продукти"],
  ["Partners", "Партньори"],
  ["Events", "Събития"],
  ["Contacts", "Контакти"]
]

const Menu = ({ MenuItems, setComp, Lang }) => {
  return (
    <div className="Menu">
      { MenuItems.map((ds, ind) => { return <MenuItem key={ind} name={ds} type={ind} setComp={setComp} Lang={Lang} /> })}
    </div>
  )
}
const MenuItem = ({ name, type, setComp, Lang }) => {
  const foo = () => { setComp(type) }
  return <button className="Button" onClick={foo} >{name[Lang]}</button>
}
const EUText = [
  ["Проект № BG16RFOP002-2.024-2039-C01 Възможност за професионална реализация в „ХАЙ ТЕКС–Р“ ЕООД\n\nСайтът е създаден с финансовата подкрепа на Оперативна програма „Иновации и конкурентоспособност“ 2014-2020, съфинансирана от Европейския съюз чрез Европейския фонд за регионално развитие.\n\nЦялата отговорност за съдържанието на сайта се носи от „ХАЙ ТЕКС–Р“ ЕООД и при никакви обстоятелства не може да се приема, че този документ отразява официалното становище на Европейския съюз и Управляващия орган.",
    ""],
  ["<b>Проект</b>: Възможност за професионална реализация в \"ХАЙ ТЕКС-Р\" ЕООД.\n\n<b>Обща цел:</b> Постигане  на устойчиво развитие и повишаване на конкурентоспособността на „ХАЙ ТЕКС–Р“  ЕООД в областта на производството на долно облекло.\n\n<b>Номер на АДПБФП:</b> BG16RFOP002-2.024-2039-C0\n\n<b>Бенефициент:</b> „ХАЙ ТЕКС–Р“  ЕООД\n\n<b>Обща стойност:</b> 180 980.00 лв., от които 123 066.40 лв. европейско и 21 717.60 лв. национално съфинансиране.\n\n<b>Начало:</b> 05.09.2019 г.\n\n<b>Край:</b> 05.03.2021 г."
    , ""]
]
const Home = ({ Lang, setOl, setComp, prodSrc, evSrc }) => {
  const [more, smore] = React.useState(false)
  let history = useHistory()

  return (
    <div>
      <AboutBrief Index={0} Color="white" Image={I6} Text={BriefAboutSource} Lang={Lang} setComp={setComp} />
      <div style={{ borderWidth: "2", borderColor: "#40937b", borderBlockStyle: "solid" }}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", color: "black" }}>
          <div style={{ flex: "1", alignSelf: "center" }}>
            <img src={"/eu1.jpg"} />
          </div>
          <div style={{ display: "flex", margin: "20px", width: "100%", flexDirection: "column", justifyContent: "flex-end" }}>
            {!more && <p style={{ wordBreak: "break-word", whiteSpace: "break-spaces", wordWrap: "break-word", lineHeight: "22px", fontSize: "18px" }}>{EUText[0][0]}</p>}
            <div className="SecLink" style={{ backgroundColor: "#204aeb", marginTop: "0px", alignSelf: "flex-end", width: "100%" }} onClick={() => { smore(!more) }}>{more ? "Скрий" : "Пълна Информация"}</div>
          </div>
          <div style={{ flex: "1", marginRight: "10px", alignSelf: "center" }}>
            <img src={"/eu2.jpg"} />
          </div>
        </div>
        {more && <p style={{ fontSize: "23px", wordWrap: "break-word", whiteSpace: "break-spaces", textAlign: "left", marginLeft: "30px", marginRight: "30px" }}>{renderHTML(EUText[1][0])}</p>}
        {more && <div className="SecLink" onClick={() => { window.open("/hitex-r.pdf") }}>{"Допълнителна информация"}</div>}
        <br />
      </div>
      <EventsBrief Index={1} Color="#abe7d6" Text={EvBriefText} Lang={Lang} setComp={setComp} EventData={evSrc} />
      <ProductsBrief dsrc={prodSrc} Lang={Lang} setOl={setOl} setComp={setComp} />
      <PrintsBrief Lang={Lang} />
    </div>
  )
}
function About({ Lang }) {
  return (
    <div>
      <div className="SecTitle" style={{ marginTop: "20px", marginBottom: "-10px" }}>{AboutTitles[0][Lang]}</div>
      <AboutSection DataSrc={AboutUsData} Findex={0} Lang={Lang} />
      <div className="SecTitle" >{AboutTitles[1][Lang]}</div>
      <AboutSection DataSrc={MissionData} Findex={1} Lang={Lang} />
    </div>
  )
}

const ProtText = ["Product Gallery", "Продуктова Галерия"]

const ProdText = [
  ["Men", "Мъже"], // 1
  ["Women", "Жени"], // 2
  ["Children", "Деца"], // 4
  ["Sleepwear", "Пижами"], // 1
  ["Underwear", "Бельо"], // 2
  ["Casual", "Ежедневни"], // 4
  ["T-Shirts", "Тениски"], // 8
  ["Sportswear", "Спортни"] // 16
]

const Products = ({ dsrc, Lang, setOl }) => {
  const [filtered, setFiltered] = React.useState(dsrc)
  const [gmen, setMen] = React.useState(true)
  const [gwom, setWom] = React.useState(true)
  const [gchi, setChi] = React.useState(true)
  const [gsleep, setSleep] = React.useState(true)
  const [gunder, setUnder] = React.useState(true)
  const [gcasu, setCasu] = React.useState(true)
  const [gshirt, setShirt] = React.useState(true)
  const [gsport, setSport] = React.useState(true)
  useEffect(() => { setFiltered(filter()) }, [gmen, gwom, gchi, gsleep, gunder, gcasu, gshirt, gsport])
  const filter = () => {
    return dsrc.filter(item => {
      let f = true
      switch (item[3]) {
        case 1: if (!gmen) f = false; break
        case 2: if (!gwom) f = false; break
        case 4: if (!gchi) f = false; break
      }
      if (!f) return f
      switch (item[4]) {
        case 1: return gsleep
        case 2: return gunder
        case 4: return gcasu
        case 8: return gshirt
        case 16: return gsport
      }
    })
  }
  const setMainCat = (cat) => {
    switch (cat) {
      case 0: setMen(true); setWom(true); setChi(true); break
      case 1: setMen(true); setWom(false); setChi(false); break
      case 2: setMen(false); setWom(true); setChi(false); break
      case 3: setMen(false); setWom(false); setChi(true); break
    }
  }
  const setProdCat = (cat) => {
    switch (cat) {
      case 0: setSleep(true); setUnder(true); setCasu(true); setShirt(true); setSport(true); break
      case 1: setSleep(true); setUnder(false); setCasu(false); setShirt(false); setSport(false); break
      case 2: setSleep(false); setUnder(true); setCasu(false); setShirt(false); setSport(false); break
      case 3: setSleep(false); setUnder(false); setCasu(true); setShirt(false); setSport(false); break
      case 4: setSleep(false); setUnder(false); setCasu(false); setShirt(true); setSport(false); break
      case 5: setSleep(false); setUnder(false); setCasu(false); setShirt(false); setSport(true); break
    }
  }
  return (
    <div style={{ marginTop: "20px", textAlign: "center" }}>
      <div className="SecTitle">{ProtText[Lang]}</div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      </div>
      <div style={{ textAlign: "left", marginLeft: "10px" }}>
        <span className="SecText" style={{ margin: "10px" }}> {(Lang ? "Продукти за:" : "Products for:")} </span>
        <button className="Button" style={{ opacity: gmen && gwom && gchi ? "100%" : "50%" }} onClick={() => { setMainCat(0) }} >{Lang ? "Всички" : "All"}</button>
        <button className="Button" style={{ opacity: gmen ? "100%" : "50%" }} onClick={() => { setMainCat(1) }} >{ProdText[0][Lang]}</button>
        <button className="Button" style={{ opacity: gwom ? "100%" : "50%" }} onClick={() => { setMainCat(2) }} >{ProdText[1][Lang]}</button>
        <button className="Button" style={{ opacity: gchi ? "100%" : "50%" }} onClick={() => { setMainCat(3) }} >{ProdText[2][Lang]}</button>
      </div>
      <div style={{ textAlign: "left", margin: "10px" }}>
        <span className="SecText" style={{ margin: "10px" }}> {(Lang ? "Категории:" : "Categories:")} </span>
        <button className="Button" style={{ opacity: gsleep && gunder && gcasu && gshirt && gsport ? "100%" : "50%" }} onClick={() => { setProdCat(0) }} >{Lang ? "Всички" : "All"}</button>
        <button className="Button" style={{ opacity: gsleep ? "100%" : "50%" }} onClick={() => { setProdCat(1) }} >{ProdText[3][Lang]}</button>
        <button className="Button" style={{ opacity: gunder ? "100%" : "50%" }} onClick={() => { setProdCat(2) }} >{ProdText[4][Lang]}</button>
        <button className="Button" style={{ opacity: gcasu ? "100%" : "50%" }} onClick={() => { setProdCat(3) }} >{ProdText[5][Lang]}</button>
        <button className="Button" style={{ opacity: gshirt ? "100%" : "50%" }} onClick={() => { setProdCat(4) }} >{ProdText[6][Lang]}</button>
        <button className="Button" style={{ opacity: gsport ? "100%" : "50%" }} onClick={() => { setProdCat(5) }} >{ProdText[7][Lang]}</button>
        <br />
        <span className="SecText" style={{ margin: "10px" }}> {filtered.length ? filtered.length + (Lang ? " резултата" : " results") : (Lang ? "Няма резултати" : "No results")} </span>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
        {filtered.map((ds, ind) => { return <Product key={ind} data={ds} lang={Lang} setOl={setOl} /> })}
      </div>
      <PrintsBrief Lang={Lang} />
    </div>
  )
}
const Partners = ({ Lang }) => {
  return (
    <div style={{ margin: "20px", width: "100%" }}>
      <div className="SecTitle" >{PartnersText[0][Lang]}</div>
      <div style={{ fontSize: "24px", textAlign: "left", margin: "50px" }}>
        <p>{PartnersText[1][Lang]}</p>
        <p>{PartnersText[2][Lang]}</p>
        <p>{PartnersText[3][Lang]}</p>
        <p>{PartnersText[4][Lang]}</p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", margin: "50px" }}>
        <img src={"/p2.jpg"} />
        <img src={"/p3.jpg"} />
        <img src={"/p1.jpg"} />
      </div>
    </div>
  )
}

const AboutTitles = [
  ["About", "За нас"],
  ["Our mission", "Нашата мисия"]
]
const AboutUsData = [
  ["a1.jpg",
    ["HiTex-R is registered in 2018 with primary field of activity “Manufacturing of knitwear products – design, modeling and manufacturing for domestic and foreign trade”.\n\n\nThe company’s administrative center is situated in Gabrovo, and its manufacturing is realized in Tryavna, Bulgaria.\n\n\nThe primary ode of economic activity in accordance with KID 2008 is 14.14 – Manufacture of underwear.",
      "Фирма ХАЙ ТЕКС-Р ЕООД е регистрирана през 2018 година с основен предмет на дейност ”Производство на трикотажни изделия - дизайн, моделиране и конструиране за вътрешно и външно търговска дейност”.\n\n\nСедалището на фирмата е в гр. Габрово, а производството се осъществява в гр. Трявна.\n\n\nОсновният код на икономическа дейност съгласно КИД 2008 е 14.14 – Производство на долно облекло."]],
  ["a2.jpg",
    ["The company organizes and realizes manufacture of knitwear products – men’s underwear, lady’s sleepwear, kid’s underwear, etc…, manufactured from high quality organic materials – cotton and its additives, as well as other materials.\n\n\nHiTex-R Ltd. is equipped with modern automated industrial machines, encompassing the technological manufacturing cycle of knitwear products – allowing it to manufacture a rich assortment of products.",
      "Дружеството организира и осъществява производство на трикотажни изделия – мъжко долно облекло, дамски пижами, детско долно облекло и др., произведени от първокласни естествени материали, памук и неговите смеси, както и други материали.\n\n\nХАЙ ТЕКС – Р ЕООД разполага със съвременни автоматизирани високотехнологични машини обхващащи технологичния цикъл на производство на трикотажни изделия и бельо,което ни позволява да изработваме богат асортимент от изделия."]],
  ["a3.jpg",
    ["The equipment is acquired through project № BG16RFOP002-2.024-2039-C01 – “Opportunities for professional realization within HiTex-R Ltd”.\n\n\nThe project is realized with the financial support of Operational Program “Innovation and Competitiveness” 2014-2020.\n\n\nThe program is co-financed by the European Union, through the EU fund for regional development.",
      "Машините и оборудването са закупени в рамките на проект № BG16RFOP002-2.024-2039-C01 - Възможност за професионална реализация в “ХАЙ ТЕКС-Р” ЕООД.\n\n\nПроектът е реализиран с финансовата подкрепа на Оперативна програма „Иновации и конкурентоспособност 2014-2020”.\n\n\nПрограмата е съфинансирана от Европейския съюз чрез европейския фонд за регионално развитие."]],
]

const MissionData = [
  ["a4.jpg",
    ["The company’s mission is to offer high-quality knitwear products – underwear, casual wear, etc…, on highly competitive pricing, in order to meet the needs and requirements of its clients.\n\nThe goals set for the undertaking are aimed toward the manufacturing of goods (knitwear products – men’s underwear, lady’s sleepwear, kid’s underwear) with excellent consumer characteristics.\n\nIn order to facilitate the realization and development of the undertaking, within the scope of the project was planned complex activities, as to create the conditions and requisites for the successful and stable future development of the company.",
      "Мисията на дружеството е да предлага висококачествени трикотажни изделия в т.ч. бельо, дрехи за свободното време и др. на конкурентни цени, с цел да задоволи конкретните нужди и потребности на клиентите си.\n\nЗаложените в предприемаческата идея цели са насочени към произвеждането на стоки (трикотажни изделия – мъжко долно облекло, дамски пижами, детско долно облекло), с много добри потребителски характеристики.\n\nЗа целите на осъществяването и развитието на предприемаческата идея, в рамките на настоящия проект са предвидени комплекс от дейности, така че да се създадат условия и предпоставки за успешното бъдещо устойчиво развитие на дружеството."]],
  ["a5.jpg",
    ["The machinery and equipment, acquired by the company, are selected and tailored to facilitate the manufacturing process, in accordance to the technological requirements of the production cycle of knitwear products.\n\n\nThe main products that will be manufactured as a result of the accomplishment of the project are men’s underwear (boxers, briefs), lady’s sleepwear and kid’s underwear.",
      "Машините и оборудването, които са закупени в рамките на проекта са подбрани така че в максимална степен да осигурят и обезпечат процеса на производството на продуктите с оглед на технологичния цикълна производството на трикотажни изделия.\n\n\nОсновни стоки които ще бъдат произвеждани и реализирани в резултат на изпълнението на настоящия проект са: мъжко долно облекло (боксери, слипове), дамски пижами и долно детско облекло."]],
]

const Events = ({ Lang, EventData }) => {
  return <Section DataSrc={EventData} Lang={Lang} />
}

const ContactData = [
  [["HiTex-R Contacts:"], ["ХайТекс-Р Контакти:"]],
  [["phone: 0896 748600\nManager"], ["тел: 0896 748600\nУправител"]],
  [["Bulgaria\nTryavna 5350"], ["Трявна 5350"]],
  [['"Shahovets" Str 14'], ['ул. "Шаховец" 14']]
]

const PartnersText = [
  ["Our Partners", "Нашите Партньори"],
  ['“Anaf” Ltd. – manufacture of sportswear and fitness clothing for the UK.', '"Анаф" ЕООД – производство на спортно и фитнес облекло за UK.'],
  ['“Seven International” Ltd. – manufacture of fitness and urban clothing.', '“Севън Интернешънъл” ЕООД – производство на спортно и ежедневно облекло.'],
  ['“Doni Style” Ltd. - manufacturing for famous Italian brands - Ducati, Ferrari and Maserati.', '“Дони Стил” ЕООД - производство за известни Италиански марки - Дукати, Ферари и Мазерати. '],
  ['“Dariteks” Ltd. - manufacture of knitwear for the German market.', '"Даритекс" ЕООД - производство на трикотаж за немския пазар.'],
]
const Contacts = ({ Lang }) => {
  return (
    <div className="Section" style={{ backgroundColor: "white" }}>
      <div className="SecText" style={{ marginLeft: '20px', display: "flex", alignContent: "center", justifyContent: "space-around", width: "100%", height: "50%", fontSize: "24px", textAlign: "right", lineHeight: "34px" }}>
        <p style={{ fontWeight: "bold" }}>{ContactData[0][Lang]}</p> <br />
        {ContactData[2][Lang]}<br />
        {ContactData[3][Lang]}<br /> <br />
        {ContactData[1][Lang]}<br /> <br />
        E-mail: <br />
        <a href="mailto:office.hitex.iv@gmail.com">office.hitex.iv@gmail.com</a>
      </div>
      <img className="SecImage" src={"/imap.jpg"} style={{ borderColor: "white" }} />
    </div>
  )
}

// DataSrc [[image, date], [title en, title bg,] [text en, text bg]]

function Section({ DataSrc, Lang }) {
  return (
    <div style={{}}>
      {DataSrc.map((item, index) => {
        let iimg = <img className="SecImage" src={item[0][1]} style={{ borderColor: ((index % 2) == 0) ? "white" : "#abe7d6", marginRight: ((index % 2) == 1) ? "0px" : "20px" }}></img>
        return (
          <div key={index} className="Section" style={{ backgroundColor: ((index % 2) == 0) ? "white" : "#abe7d6", alignItems: "center", justifyContent: "space-between" }}>
            {((index % 2) == 1) && iimg}
            <div className="SecText" style={{ marginLeft: '20px', marginRight: ((index % 2) == 0) ? "0px" : "20px" }}>
              <div style={{ textAlign: "center" }}>{item[0][0]}</div>
              <div className="SecTitle">{item[1][Lang]}</div>
              <div>{item[2][Lang]}</div>
            </div>
            {!(index % 2) && iimg}
          </div>
        )
      })}
    </div>
  )
}

function AboutSection({ DataSrc, Lang, Findex }) {
  return (
    <div style={{}}>
      {DataSrc.map((item, index) => {
        let odd = (((index + Findex) % 2) == 0)
        let iimg = <img className="SecImage" src={item[0]} style={{ borderColor: odd ? "white" : "#abe7d6", marginRight: !odd ? "0px" : "20px" }}></img>
        return (
          <div key={index} className="Section" style={{ backgroundColor: odd ? "white" : "#abe7d6", alignItems: "center", justifyContent: "space-between" }}>
            {!odd && iimg}
            <div className="SecText" style={{ marginLeft: '20px', marginRight: odd ? "0px" : "20px", lineHeight: "30px" }}>
              <div>{item[1][Lang]}</div>
            </div>
            {odd && iimg}
          </div>
        )
      })}
    </div>
  )
}

const PrintBody = [
  "Create your personal and unique clothing, by your own design, in a matter of minutes.\n\nFull-color high-resolution print of vector and raster images.\n\nInvent your unique style with HiTex-R.",
  "Създайте своите уникални и неповторими дрехи, по собствен дизайн, в рамките на броени минути.\n\nПечат на пълноцветни векторни и растерни изображения с висока резолюция.\n\nОткрийте своя уникален стил с ХайТекс-Р."
]


const BriefAboutEnTitle = "About"
const BriefAboutEn = 'HiTex-R is a manufacturer of knitwear products – men’s underwear, lady’s sleepwear, kid’s underwear and casual wear. It’s manufacturing facility is located in Tryavna, Bulgaria.\n\nThe company is created and managed by Ms. Ilyana Velusheva, a person of knowledge, skills, competence and long professional experience in the knitwear industry.\n\nThe company is awarded financing under the EU operational program “Innovation and Competitiveness”, through which it acquired industrial sewing machines, manufacturing equipment and a textile printer, in order to expand its range of products.'
const BriefAboutEnMore = "Learn More"

const BriefAboutBgTitle = "За нас"
const BriefAboutBg = 'ХайТекс-Р ЕООД е производител на трикотажни изделия - мъжко бельо, дамски пижами, детско долно облекло, облекла за свободното време. Производствената площадка се намира в град Трявна.\n\nФирмата е създадена и ръководена от г-жа Илияна Велушева, която притежава знания, умения, компетенции и дългогодишен професионален опит в областта на трикотажната индустрия.\n\nКомпанията печели финансиране по оперативна програма ”Иновации и Конкурентноспособност”на ЕС, чрез което успя да закупи високотехнологични шевни машини и производствено оборудване в т.ч. и принтер за печат върху текстил, за да разшири обхвата на продуктовото си производство.'
const BriefAboutBgMore = "Научи повече"

const BriefAboutSource = [
  [BriefAboutEnTitle, BriefAboutEn, BriefAboutEnMore],
  [BriefAboutBgTitle, BriefAboutBg, BriefAboutBgMore]
]

const AboutBrief = ({ Index, Color, Image, Text, Lang, setComp }) => {
  let imgside = <img className="SecImage" src={Image} style={{ borderColor: Color }}></img>
  function foo() { setComp(1) }
  return (
    <div className="Section" style={{ backgroundColor: Color }}>
      {((Index % 2) == 1) && imgside}
      <div className="SecText" style={{ marginLeft: '20px' }}>
        <div className="SecTitle">{Text[Lang][0]}</div>
        <div>{Text[Lang][1]}</div>
        <div className="SecLink" onClick={foo}>{Text[Lang][2]}</div>
      </div>
      {!(Index % 2) && imgside}
    </div>
  )
}

// event
// event date
// event title
// event image
// event text

const EvBriefText = [
  ["Events", "Събития"],
  ["All Events", "Всички събития"]
]

const EventsBrief = ({ Index, Color, Lang, setComp, Text, EventData }) => {
  const [cev, setCev] = React.useState(0)
  let imgside = <img className="SecImage" src={EventData.length ? EventData[cev][0][1] : ''} style={{ borderColor: Color }}></img>
  function foo() { setComp(4) }
  return (
    <div className="Section" style={{ backgroundColor: Color, textAlign: "center" }}>
      {((Index % 2) == 1) && imgside}
      <div className="SecText" style={{ marginRight: '20px', justifyContent: "space-between", width: "100%" }}>
        <div className="SecTitle">{Text[0][Lang]}</div>
        <div style={{ textAlign: "center" }}>
          {EventData.slice(0, 2).map((item, index) => {
            return (
              <div key={index} onClick={() => { setCev(index) }} style={{ cursor: "pointer" }}>
                {(index !== 0 && index !== 1) && <img src={"evbreak.png"} />}
                <div style={{ marginLeft: "20px", textAlign: "left", fontSize: "16px" }}>{item[0][0]}</div>
                <div style={{ marginLeft: "20px", fontWeight: "bold", textAlign: "left", fontSize: "22px", marginBottom: "10px" }}>{item[1][Lang]}</div>
                <div style={{ marginLeft: "20px", textAlign: "left" }}>{item[2][Lang]}</div>
                {index !== 1 && <img src={"/evbreak.png"} />}
              </div>
            )
          })}
        </div>
        <div className="SecLink" onClick={foo}>{Text[1][Lang]}</div>
      </div>
      {!(Index % 2) && imgside}
    </div>
  )
}


// 1male 2female 4children
// 1sleepwear, 2underwear, 4casual wear, 8tshirts, 16 sportswear

const LastProd = ["Latest Products", "Последни продукти"]
const AllProd = ["All Products", "Всички Продукти"]
// latest products
const ProductsBrief = ({ dsrc, Lang, setOl, setComp }) => {
  function foo() { setComp(2) }
  return (
    <div style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}>
      <div className="SecTitle">{LastProd[Lang]}</div>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
        {dsrc.map((ds, ind) => { return <Product key={ind} data={ds} lang={Lang} setOl={setOl} /> })}
      </div>
      <div className="SecLink" onClick={foo} style={{ marginLeft: "20px", marginRight: "20px" }} >{AllProd[Lang]}</div>
    </div>
  )
}

const Product = ({ data, lang, setOl }) => {
  let foo = () => { setOl("/product_" + data[0] + ".jpg") }
  return (
    <div style={{ marginBottom: "10px", textDecoration: "none", color: "black", cursor: "pointer", alignContent: "center" }} onClick={foo}>
      <img src={"/thumb_" + data[0] + ".jpg"}></img>
      <p style={{ wordWrap: "break-word", width: "200px", textAlign: "center" }}>{data[1 + lang]}</p>
    </div>
  )
}

const PrintText = ["Custom Prints", "Печат върху текстил"]

const PrintsBrief = ({ Lang }) => {
  return (
    <div className="Print">
      <div className="SecTitle" style={{ paddingTop: "35px" }}>{PrintText[Lang]}</div>
      <img src={TexPrint}></img>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <img src={KNK}></img>
        <div className="SecText" style={{ textAlign: "center", fontSize: "26px", lineHeight: "30px", justifyContent: "center", margin: "30px" }}>{PrintBody[Lang]}</div>
      </div>
    </div>
  )
}

// admin
// manage events
// manage products

export default App;
