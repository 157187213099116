import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// TODOs - craete components, nest components with props, drive dynamic content via json
// implement a backend, basic fs data storage, user authenticated session
// implement the design

// the mobile version is just the appbody component
// without the external decorations
// so it must be made to fit width
// and desktop version sets the width to 900
// additionally the mobile versio has larger menu and lang items

// language support - use a simple js object for text and switch the object

// exclude side images from page width 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
